<template>
  <div class="content-wrapper">
    <h1 class="page-title">문의하기</h1>
    <div class="page-wrapper">
      <form @submit.prevent="submitForm">
        <div class="mb-3">
          <label for="title" class="form-label">제목</label>
          <input type="text" v-model="title" class="form-control" id="title" />
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">이메일</label>
          <input type="email" v-model="email" class="form-control" id="email" />
        </div>
        <div class="mb-3">
          <label for="name" class="form-label">이름</label>
          <input type="text" v-model="name" class="form-control" id="name" />
        </div>
        <div class="mb-3">
          <label for="phone" class="form-label">연락처</label>
          <input type="text" v-model="phone" class="form-control" id="phone" />
        </div>
        <div class="mb-5">
          <label for="description" class="form-label">문의내용</label>
          <textarea
            v-model="description"
            class="form-control"
            id="description"
            rows="10"
          ></textarea>
        </div>
        <div class="mb-3 text-center">
          <button
            :disabled="
              !isEmailValid || !title || !name || !phone || !description
            "
            type="submit"
            class="btn btn-default page-submit-btn"
          >
            문의하기
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { validateEmail } from '../../utils/validation.js';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      title: '',
      email: '',
      name: '',
      phone: '',
      description: '',
    };
  },
  computed: {
    isEmailValid() {
      return validateEmail(this.email);
    },
  },
  methods: {
    ...mapActions(['CREATE_CONTACT']),
    submitForm() {
      try {
        const createData = {
          title: this.title,
          email: this.email,
          name: this.name,
          phone: this.phone,
          description: this.description,
        };

        this.CREATE_CONTACT(createData);

        this.title = '';
        this.email = '';
        this.name = '';
        this.phone = '';
        this.description = '';

        this.$toast.success('접수가 완료되었습니다');
      } catch (error) {
        this.$toast.error(
          '접수 중 오류가 생겼습니다. 나중에 다시 시도해주세요'
        );
      }
    },
  },
};
</script>

<style scoped>
.page-title {
  margin: 0;
  font-size: 30px;
  color: #333;
}
.page-wrapper {
  padding-top: 40px;
}
.form-label {
  font-size: 18px;
  color: #333;
}
.page-submit-btn {
  width: 250px;
  height: 60px;
  border-radius: 3px;
  background-color: #333;
  color: #fff;
}
</style>
