<template>
  <div>
    <ContactComponent />
  </div>
</template>

<script>
import ContactComponent from '@/components/contact/ContactComponent.vue';

export default {
  components: {
    ContactComponent,
  },
};
</script>

<style></style>
